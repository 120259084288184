:root {
  --bs-primary: #7c0b1d;
  --bs-primary-varint: #630917;
  --bs-secondary: #1e243a;
  --bs-secondary-varint: #233049;

  --bs-primary-rgb: rgb(124, 11, 29);
  --bs-secondary-rgb: rgb(21, 25, 40);

  --bs-light: #fff;
  --bs-gray-400: #dae1e7;
  --bs-gray-500: #aeb4be;
  --bs-gray-600: #7d879c;

  --duration-100: all 100ms ease-in-out;
}

/* .swiper-button-next {
  background-image: url(Assets/Images/right.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  width: 80px !important;
  height: 80px !important;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev {
  background-image: url(Assets/Images/left.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  width: 80px !important;
  height: 80px !important;
}

.swiper-button-prev::after {
  display: none;
} */

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
}
@media (max-width: 576px) {
  .fs-2xs {
    font-size: 0.5rem;
  }
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1600px;
  }
}

.scrollable .simplebar-horizontal,
.scrollable .simplebar-vertical {
  display: none;
}

.scrollable .simplebar-mask {
  position: relative;
  z-index: 1;
}

.single-category .card {
  padding: 10px;
  height: 320px !important;
  max-height: 320px !important;
  width: 100%;
  max-width: 100%;
}

.single-category .card .card-img-top {
  height: 250px !important;
  max-height: 250px !important;
  width: 100%;
  max-width: 100%;
  object-fit: contain;
}

.rec .rec-dot {
  background-color: #bc9e576f !important;
  outline: none !important;
  width: 15px;
  border-radius: 5px !important;
  box-shadow: none !important;
  height: 5px !important;
}
.rec .rec-dot_active {
  background-color: var(--bs-primary) !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  width: 40px;
  height: 5px !important;
}
.swiper-pagination-bullet {
  background-color: var(--bs-light) !important;
  outline: none !important;
  width: 15px !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  height: 5px !important;
  transition: all 0.3s ease-in-out !important;
}
.swiper-pagination-bullet-active {
  background-color: var(--bs-primary) !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  width: 45px !important;
  height: 5px !important;
}

@media only screen and (max-width: 600px) {
  .hideOnMobile {
    display: none;
  }
}
/* width */
/* ::-webkit-scrollbar {
  width: 5px;
  height: 7px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #9d99c0;
  border-radius: 10px;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #bc9e57;
  border-radius: 10px;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #bc9e57;
} */

.radius-10 {
  border-radius: 10px;
}
.radius-15 {
  border-radius: 15px;
}
.radius-20 {
  border-radius: 20px;
}
.radius-25 {
  border-radius: 25px;
}
.radius-30 {
  border-radius: 30px;
}
.families-data .swiper-wrapper {
  justify-content: center !important;
}
.flex-1 {
  flex: 1 !important;
}
.cursor-auto {
  cursor: auto;
}
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-auto {
  cursor: auto !important;
}
.img-scale {
  transition: 250ms all ease-in-out;
}
.img-scale:hover {
  transform: scale(1.05);
}
.text-container {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Number of lines */
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5; /* Adjust based on your font size */
}
.loader {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #fff #fff transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent #ff3d00 #ff3d00;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: #373f50 #373f50 transparent;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  animation: rotation 1.75s linear infinite;
  transform-origin: center center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.max-w-1600 {
  max-width: 1600px;
}
.top-1 {
  top: 0.5rem;
}
.left-1 {
  left: 0.5rem;
}

.fav-icon {
  background: transparent;
  border-radius: 0.25rem;
  border: 0;
  backdrop-filter: blur(4px) !important;
  border-bottom: 2px solid var(--bs-primary);
  border-top: 2px solid var(--bs-primary);
}

.profile-menu-item {
  color: var(--bs-light) !important;
}

.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}
.h-custom {
  height: calc(100% - 73px);
}
@media (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
}
.search-Input {
  border: 1px solid var(--bs-gray-400);
  border-radius: 0.35rem;
}
.green-row {
  background-color: #6e954e !important;
  color: var(--bs-light);
}
.red-row {
  background-color: #b0464b !important;
  color: var(--bs-light);
}
.h-screen {
  height: 100vh;
}

/* Loading Design  */
.loader-admin {
  display: inline-block;
  text-align: center;
  line-height: 86px;
  text-align: center;
  position: relative;
  padding: 0 60px;
  font-size: 60px;
  font-family: Arial, Helvetica, sans-serif;
  color: var(--bs-secondary);
}
.loader-admin:before,
.loader-admin:after {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  background: currentColor;
  position: absolute;
  animation: load-admin 0.7s infinite alternate ease-in-out;
  top: 0;
}
.loader-admin:after {
  top: auto;
  bottom: 0;
}
@keyframes load-admin {
  0% {
    left: 0;
    height: 43px;
    width: 15px;
    transform: translateX(0);
  }
  50% {
    height: 10px;
    width: 40px;
  }
  100% {
    left: 100%;
    height: 43px;
    width: 15px;
    transform: translateX(-100%);
  }
}
.object-cover {
  object-fit: cover !important        ;
}
.scale-110 {
  transition: var(--duration-100);
}
.scale-110:hover {
  transform: scale(1.1);
}

.list-image {
  width: 8rem;
  height: 8rem;
}

/* ! NAB IMAGE */
.nav-img-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  padding: 0.25rem 2rem 0.25rem 0;
  background: linear-gradient(
    87deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(49, 66, 99, 1) 100%
  );
  border-radius: 2rem;
  width: 15rem;
}
.nav-img-container > img {
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  background: #fff;
  border-radius: 2rem;
}

/* ! END NAB IMAGE */
.max-w-500 {
  max-width: 500px;
}

.fs-sm-md-lg-1 {
  font-size: 0.75rem;
}
.fs-sm-md-lg-2 {
  font-size: 0.6rem;
}

@media (min-width: 768px) {
  .fs-sm-md-lg-1 {
    font-size: 1.1rem;
  }
  .fs-sm-md-lg-2 {
    font-size: 0.75rem;
  }
}
@media (min-width: 1024px) {
  .fs-sm-md-lg-1 {
    font-size: 1.2rem;
  }
  .fs-sm-md-lg-2 {
    font-size: 1rem;
  }
}
